<script>
import { postPromotion, putPromotion } from "@/newApi/market";
import { getGoodsSelect, getGoodsAttr } from "@/newApi/commit";
export default {
  name: "deawerMarket",
  props: {
    type: {
      type: Number,
      default: 1,
    },
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
    btn_type: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      options: [], //商品数组
      options1: [], //商品规格数组
      add_type: [],
      value1: [],
      steps: [
        {
          full_amount: "",
          reduce_amount: "",
          give_goods_id: 0,
          give_goods_count: 0,
          give_spec_id: 0,
        },
      ],
      typeList: [
        {
          value: 1,
          label: "满减券",
        },
        {
          value: 2,
          label: "折扣券",
        },
      ],
    };
  },
  watch: {
    "steps.0.full_amount": {
      handler(val) {
        console.log(val);
        if (val) {
          this.info.discount_type = "1";
        }
      },
      deep: true,
    },
    "info.per_amount": {
      handler(val) {
        console.log(val);
        if (val) {
          this.info.discount_type = "2";
          this.steps = [
            {
              full_amount: "",
              reduce_amount: "",
              give_goods_id: 0,
              give_goods_count: 0,
            },
          ];
        }
      },
      deep: true,
    },
  },
  components: {},
  //生命周期
  created() {
    console.log(this, 212312);
    if (this.btn_type == "edit") {
      this.value1 = [this.info.start_time, this.info.end_time];
      if (this.info.steps.length > 0) {
        this.steps = this.info.steps;
      }
      if (this.info.add_type.length > 0) {
        this.add_type = this.info.add_type[0] == "1" ? ["优惠券"] : [];
        console.log(235677888);
      }
      if (this.info.give_goods_id || this.steps[0].give_goods_id) {
        let data = {
          id: this.info.give_goods_id || this.steps[0].give_goods_id,
        };
        getGoodsAttr(data).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.options1 = res.data;
          }
        });
      }
    }
    this._getGoodsSelect();
  },
  methods: {
    changeGoods1(e, index) {
      console.log(e, index);
      let data = {
        id: e,
      };
      this.steps[index].give_spec_id = null;
      this._getGoodsAttr(data);
    },
    changeGoods(e) {
      console.log(e);
      let data = {
        id: e,
      };
      this.info.give_spec_id = null;
      this._getGoodsAttr(data);
    },
    _getGoodsAttr(data) {
      getGoodsAttr(data).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.options1 = res.data;
        }
      });
    },
    //商品下拉选择
    _getGoodsSelect() {
      getGoodsSelect().then((res) => {
        if (res.code == 200) {
          this.options = res.data;
        }
      });
    },
    //删除优惠设置
    del(index) {
      if (this.steps.length > 1) {
        this.steps.splice(index, 1);
      } else {
        this.$message.error("最少保留一条优惠");
      }
    },
    //添加优惠设置
    add() {
      this.steps.push({
        full_amount: "",
        reduce_amount: "",
        give_goods_id: 0,
        give_goods_count: 0,
        give_spec_id: 0,
      });
    },
    onSuccessCover(urlAddr) {
      console.log(urlAddr);
      this.info.cover = urlAddr;
    },
    changeDate(e) {
      this.info.start_time = e[0];
      this.info.end_time = e[1];
      console.log(e, this.info);
    },
    //取消
    closeDrawer() {
      let drawers = false;
      this.$emit("closeDrawer", drawers);
      this.$emit("getList");
    },
    _postPromotion(data) {
      postPromotion(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("新增成功");
          this.$emit("getList");
          this.closeDrawer();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    _putPromotion(data) {
      putPromotion(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("编辑成功");
          this.$emit("getList");
          this.closeDrawer();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //确定保存
    handleConfirm(row) {
      console.log(this.info, this.add_type);
      if (!this.info.name) return this.$message.error("请填写优惠券名称");
      else if (this.value1.length != 2)
        return this.$message.error("请选择活动时间");
      else if (!this.info.discount_add_type)
        return this.$message.error("请选择优惠叠加方式");
      else if (this.info.discount_add_type == 2 && this.add_type.length == 0)
        return this.$message.error("请至少选择一种优惠叠加活动");
      else if (this.type == 1) {
        if (!this.info.discount_type)
          return this.$message.error("请选择优惠方式");
        else if (this.info.discount_type == 1 && this.steps.length == 0)
          return this.$message.error("请至少添加一条优惠设置");
        else if (
          this.info.discount_type == 2 &&
          (!this.info.per_amount || !this.info.reduce_amount)
        )
          return this.$message.error("请输入优惠金额");
        else if (!this.info.goods_type)
          return this.$message.error("请选择适用商品");
        else if (
          (this.info.goods_type == 2 || this.info.goods_type == 3) &&
          (!this.info.goods_ids || this.info.goods_ids.length == 0)
        )
          return this.$message.error("请选择适用商品");
      } else if (this.type == 2) {
        if (!this.info.discount_type)
          return this.$message.error("请选择优惠方式");
        else if (this.info.discount_type == 1 && this.steps.length == 0)
          return this.$message.error("请至少添加一条优惠设置");
        else if (
          this.info.discount_type == 2 &&
          (!this.info.per_amount || !this.info.give_goods_id)
        )
          return this.$message.error("请选择赠送商品");
        else if (!this.info.goods_type)
          return this.$message.error("请选择适用商品");
        else if (
          (this.info.goods_type == 2 || this.info.goods_type == 3) &&
          (!this.info.goods_ids || this.info.goods_ids.length == 0)
        )
          return this.$message.error("请选择适用商品");
      } else {
        if (!this.info.active_goods_id)
          return this.$message.error("请选择活动商品");
        else if (!this.info.give_goods_id)
          return this.$message.error("请选择赠送商品");
        else if (!this.info.give_goods_count)
          return this.$message.error("请输入赠送数量");
      }
      this.info.add_type = [];
      this.add_type.forEach((item) => {
        if (item == "优惠券") {
          this.info.add_type.push(1);
        } else if (item == "积分抵现") {
          this.info.add_type.push(2);
        } else if (item == "满赠") {
          this.info.add_type.push(3);
        } else if (item == "满减") {
          this.info.add_type.push(4);
        }
      });
      this.info.steps = this.steps;
      this.info.type = this.type;
      if (this.btn_type == "add") {
        this._postPromotion(this.info);
      } else {
        console.log(this.info, "423434");
        this._putPromotion(this.info);
      }
      console.log(this.info);
    },
  },
};
</script>

<template>
  <div class="gg-container content">
    <div style="margin-bottom:20px;">
      <span style="font-size:25px;border-bottom:3px solid #1684FC;"
        >{{ btn_type == "add" ? "新增" : "编辑" }}满N{{
          type == 1 ? "元减" : type == 2 ? "元送" : "赠N"
        }}活动</span
      >
    </div>
    <div class="content">
      <div style="margin-bottom:20px;">
        <span style="margin-right:20px;"
          ><span style="color:red">*</span>活动名称</span
        >
        <el-input
          v-model="info.name"
          size="mini"
          class="input"
          @input="$forceUpdate()"
        ></el-input>
      </div>
      <div style="margin-bottom:20px;">
        <span style="margin-right:20px;"
          ><span style="color:red">*</span>活动时间</span
        >
        <el-date-picker
          v-model="value1"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="changeDate"
        >
        </el-date-picker>
      </div>
      <div style="display: flex;margin-bottom:20px;">
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>优惠叠加</span
        >
        <div style="display: flex;flex-direction: column;">
          <div style="display: flex;flex-direction: column;">
            <el-radio
              v-model="info.discount_add_type"
              label="1"
              @change="$forceUpdate()"
              >不叠加其他营销活动</el-radio
            >
            <div
              style="width:300px;color:#B7B8B7;line-height: 1.5;margin:15px 0"
            >
              不叠加时,若活动商品同时配置了其他活动,则无法参加满减活动,如希望同时参与其他营销活动,则需选改为"叠加其他营销活动",并勾选对应活动类型
            </div>
          </div>
          <div style="display: flex;align-items: center;">
            <el-radio
              @change="$forceUpdate()"
              v-model="info.discount_add_type"
              label="2"
              style="margin:10px 0"
              >叠加其他营销活动</el-radio
            >
            <div
              v-if="info.discount_add_type == 2"
              style="margin-left:20px;display: flex;align-items: center;"
            >
              <span style="font-weight: bold;margin-right:15px"
                >可叠加活动</span
              >
              <el-checkbox-group v-model="add_type">
                <el-checkbox label="优惠券"></el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
      <div
        style="display: flex;margin-bottom:20px;"
        v-if="type == 1 || type == 2"
      >
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>优惠设置</span
        >
        <div style="display: flex;flex-direction: column;">
          <div>
            <el-radio
              v-model="info.discount_type"
              label="1"
              @change="$forceUpdate()"
              >阶梯满减</el-radio
            >
            <div class="box">
              <div
                style="display: flex;align-items: center;margin-top:10px"
                v-for="(item, index) in steps"
                :key="index"
              >
                <span style="margin-right:30px">{{ index + 1 }}级优惠</span>
                <div style="display: flex;align-items: center;">
                  <div
                    style="display: flex;align-items: center;"
                    v-if="type == 1"
                  >
                    满<el-input
                      size="mini"
                      v-model="item.full_amount"
                      class="input-radio"
                    ></el-input
                    >减<el-input
                      size="mini"
                      v-model="item.reduce_amount"
                      class="input-radio"
                    ></el-input
                    >元
                  </div>
                  <div
                    style="display: flex;align-items: center;"
                    v-if="type == 2"
                  >
                    满<el-input
                      size="mini"
                      v-model="item.full_amount"
                      class="input-radio"
                    ></el-input
                    >元 送
                    <el-select
                      filterable
                      clearable
                      v-model="item.give_goods_id"
                      placeholder="请选择"
                      style="width:150px;margin:0 15px;"
                      @change="changeGoods1($event, index)"
                      size="mini"
                    >
                      <el-option
                        v-for="item1 in options"
                        :key="item1.id"
                        :label="item1.goods_name"
                        :value="item1.id"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      filterable
                      clearable
                      v-model="item.give_spec_id"
                      placeholder="请选择商品规格"
                      size="mini"
                      style="width:100px;margin:0 15px;"
                    >
                      <el-option
                        v-for="item1 in options1"
                        :key="item1.goods_attr_id"
                        :label="item1.goods_attr_name"
                        :value="item1.goods_attr_id"
                      >
                      </el-option>
                    </el-select>
                    赠送数量<el-input
                      size="mini"
                      v-model="item.give_goods_count"
                      class="input-radio"
                    ></el-input>
                  </div>
                  <span
                    style="color:red;margin-left:20px;cursor: pointer;"
                    @click="del(index)"
                    >删除</span
                  >
                </div>
              </div>
              <div
                v-if="steps.length < 5"
                style="margin-top:20px;color:#1684FC;cursor: pointer;width:100px"
                @click="add"
              >
                添加优惠
              </div>
              <div
                style="margin-top:20px;font-weight: bold;font-size:12px;margin-bottom:15px;"
              >
                提醒: 每级优惠不叠加, 例如:
                满足二级优惠条件后则不再享有一级优惠, 最多五级优惠
              </div>
            </div>
          </div>
          <div>
            <el-radio
              v-model="info.discount_type"
              label="2"
              @change="$forceUpdate()"
              >循环满减</el-radio
            >
            <div class="box">
              每<el-input
                size="mini"
                v-model="info.per_amount"
                @input="$forceUpdate()"
                class="input-radio"
              ></el-input
              >元<span v-if="type == 1"
                >减<el-input
                  size="mini"
                  v-model="info.reduce_amount"
                  @input="$forceUpdate()"
                  class="input-radio"
                ></el-input
                >元</span
              >
              <span v-if="type == 2">
                送<el-select
                  filterable
                  clearable
                  v-model="info.give_goods_id"
                  @input="changeGoods"
                  placeholder="请选择"
                  size="mini"
                  style="width:200px;margin:0 15px;"
                >
                  <el-option
                    v-for="item1 in options"
                    :key="item1.id"
                    :label="item1.goods_name"
                    :value="item1.id"
                  >
                  </el-option>
                </el-select>
                <el-select
                  filterable
                  clearable
                  v-model="info.give_spec_id"
                  placeholder="请选择商品规格"
                  size="mini"
                  style="width:100px;margin:0 15px;"
                  @change="$forceUpdate()"
                >
                  <el-option
                    v-for="item1 in options1"
                    :key="item1.goods_attr_id"
                    :label="item1.goods_attr_name"
                    :value="item1.goods_attr_id"
                  >
                  </el-option>
                </el-select>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        style="display: flex;margin-bottom:20px;"
        v-if="type == 1 || type == 2"
      >
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span>适用商品</span
        >
        <div style="display: flex;">
          <div style="display: flex;flex-direction: column;">
            <el-radio
              v-model="info.goods_type"
              label="1"
              @change="$forceUpdate()"
              >全部商品可用</el-radio
            >
            <el-radio
              v-model="info.goods_type"
              label="2"
              style="margin:10px 0"
              @change="$forceUpdate()"
              >指定商品可用</el-radio
            >
            <el-radio
              v-model="info.goods_type"
              label="3"
              @change="$forceUpdate()"
              >指定商品不可用</el-radio
            >
          </div>
          <!-- <div v-if="info.goods_type == 2 || info.goods_type == 3">
            <el-select
              filterable
              clearable
              v-model="info.goods_ids"
              multiple
              @change="$forceUpdate()"
              :placeholder="
                info.goods_type == 2 ? '请选择适用商品' : '请选择不适用商品'
              "
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.goods_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div> -->
        </div>
      </div>
      <div
        style="display: flex;margin-bottom:20px;"
        v-if="info.goods_type == 2 || info.goods_type == 3"
      >
        <span style="margin-right:20px;margin-bottom:20px;"
          ><span style="color:red">*</span
          >{{ info.goods_type == 2 ? "适用商品" : "不适用商品" }}</span
        >
        <div style="display: flex;">
          <div v-if="info.goods_type == 2 || info.goods_type == 3">
            <el-select
              filterable
              clearable
              v-model="info.goods_ids"
              multiple
              placeholder="请选择"
              @change="$forceUpdate()"
              size="mini"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.goods_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div style="margin-bottom:20px;" v-if="type == 3">
        <span style="margin-right:20px;"
          ><span style="color:red">*</span>活动商品</span
        >
        <el-select
          filterable
          clearable
          v-model="info.active_goods_id"
          placeholder="请选择"
          size="mini"
          @change="$forceUpdate()"
          style="width:200px;margin:0 15px;"
        >
          <el-option
            v-for="item1 in options"
            :key="item1.id"
            :label="item1.goods_name"
            :value="item1.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom:20px;" v-if="type == 3">
        <span style="margin-right:20px;"
          ><span style="color:red">*</span>购买数量</span
        >
        <el-input
          v-model="info.active_goods_count"
          size="mini"
          class="input"
          @input="$forceUpdate()"
        ></el-input>
      </div>
      <div style="margin-bottom:20px;" v-if="type == 3">
        <span style="margin-right:20px;"
          ><span style="color:red">*</span>赠送商品</span
        >
        <el-select
          filterable
          clearable
          v-model="info.give_goods_id"
          placeholder="请选择"
          size="mini"
          style="width:200px;margin:0 15px;"
          @change="changeGoods"
        >
          <el-option
            v-for="item1 in options"
            :key="item1.id"
            :label="item1.goods_name"
            :value="item1.id"
          >
          </el-option>
        </el-select>
        <el-select
          filterable
          clearable
          v-model="info.give_spec_id"
          placeholder="请选择商品规格"
          size="mini"
          style="width:200px;margin:0 15px;"
          @change="$forceUpdate()"
        >
          <el-option
            v-for="item1 in options1"
            :key="item1.goods_attr_id"
            :label="item1.goods_attr_name"
            :value="item1.goods_attr_id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom:20px;" v-if="type == 3">
        <span style="margin-right:20px;"
          ><span style="color:red">*</span>赠送数量</span
        >
        <el-input
          v-model="info.give_goods_count"
          size="mini"
          class="input"
          @input="$forceUpdate()"
        ></el-input>
      </div>
    </div>
    <div class="footer">
      <el-button size="small" @click="closeDrawer">取 消</el-button>
      <el-button type="primary" size="small" @click="handleConfirm('info')"
        >确 定</el-button
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.box {
  padding: 10px 20px;
  box-sizing: border-box;
  width: 800px;
  height: auto;
  border: 3px solid #dcdfe6;
  border-radius: 10px;
  margin: 10px 0;
}
.input {
  width: 200px;
}
.content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}
.footer {
  position: absolute;
  bottom: 100px;
  right: 100px;
}
.input-radio {
  width: 60px;
  margin: 0 15px;
}
</style>
